* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Open Sans;
    padding: 1rem;
    background-color: burlywood;
}

.modal-popup-opened {
    filter: blur(4px);
}
