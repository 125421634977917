.modal-popup-center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
}

.popupp-body {
    background-color: #fbfdff;

    padding: 5rem 4rem;
    position: relative;
    overflow: scroll;
}

.popup-close-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    border: 2px solid black;
    border-radius: 4px;
    display: flex;
    padding: 4px;
}

.popup-add-icon {
    margin: 0 0.5rem;
    margin-bottom: -0.2rem;
}

.popup-text-big {
    font-size: 2.5rem;
    font-weight: 300;
    text-align: center;
    margin-bottom: 2rem;
}

.popup-text-small {
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    line-height: 2rem;
}

.popup-arrow-down {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;

    border-top: 40px solid #fbfdff;
}

.modal-popup-wrapper-inner {
    flex-flow: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 85%;
    margin-bottom: -12%;
}
